




















































import { Facility } from "@/models";
import { BIconCollectionPlay } from "bootstrap-vue";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from "@/mixins";
import { LocationFacilities as LocationFacilitiesService } from "@/services/SOLO";

@Component({
  components: {
    LoadingPanel: () => import("@/components/LoadingPanel"),
  },
  mixins: [translations],
})
export default class LocationFacilities extends Vue {
  @Prop() locationFacilities!: Array<Facility>;
  @Prop() availableFacilities!: Array<Facility>;
  @Prop() locationId!: string;
  @Prop() loading!: Boolean;

  localLocationFacilities!: Array<Facility>;
  localAvailableFacilities!: Array<Facility>;

  translate!: Function;
  $notify: any;
  removingFacility: Boolean = false;

  @Watch("locationFacilities", { immediate: true, deep: true })
  locationFacilitiesChanged(newVal: Array<Facility>, oldVal: Array<Facility>) {
    this.localLocationFacilities = newVal;
  }

  @Watch("availableFacilities", { immediate: true, deep: true })
  availableFacilitiesChanged(newVal: Array<Facility>, oldVal: Array<Facility>) {
    this.localAvailableFacilities = newVal;
  }

  created() {
    if (this.locationFacilities) {
     this.localLocationFacilities = this.locationFacilities;
    }

    if (this.availableFacilities) {
      this.localAvailableFacilities = this.availableFacilities;
    }
  }
  private async enableFacility(facility: Facility) {
    try {
      let response = await LocationFacilitiesService.store(
        this.locationId,
        facility.id
      );

      let locationFacility = this.localAvailableFacilities.find(
        (item) => facility.id === item.id
      );

      if (locationFacility) {
        this.localLocationFacilities.push(locationFacility);
        this.$emit('locationFacilities:updated', this.localLocationFacilities);
      }

      this.$notify({
          title: this.translate("FACILITY ENABLED!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Facility have been enabled successfully"),
          type: "success",
          icon: "fas fa-check",
        });
    } catch (error) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }
  private async disableFacility(facility: Facility) {
    try {
      let response = await LocationFacilitiesService.destroy(
        this.locationId,
        facility.id
      );

      let filteredLocationFacilities = this.localLocationFacilities.filter(
        (item) => facility.id !== item.id
      );

      this.localLocationFacilities = filteredLocationFacilities;
      this.$emit('locationFacilities:updated', this.localLocationFacilities);
      this.$notify({
          title: this.translate("FACILITY DISABLED!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Facility have been disabled successfully"),
          type: "success",
          icon: "fas fa-check",
        });
    } catch (error) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }
  private isEnabled(facility: Facility) {
    let locationFacility = this.locationFacilities.find(
      (item) => facility.id === item.id
    );
    return locationFacility ? true : false;
  }
}
